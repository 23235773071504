import React, { useState }              from 'react';
import { FaArrowRight, FaArrowLeft }    from 'react-icons/fa';
import { GoX }                          from 'react-icons/go';
import PropTypes                        from 'prop-types';
import Img                              from 'gatsby-image';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import useMousetrap                     from 'react-hook-mousetrap';

import * as S from './styles';

// TODO add preloading or loading animation
const Lightbox = props => {
  const { images, thumbnails, thumbnailStyle } = props;
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const onImageClick = (index) => {
    setLightboxOpen(true);
    setSelectedImageIndex(index);
  };
  const onClose = () => {
    setLightboxOpen(false);
    setSelectedImageIndex(null);
  };
  const onNext = (e) => {
    if (e) {
      e.stopPropagation();
    }
    let nextIndex = selectedImageIndex + 1;
    if (nextIndex === images.length) {
      nextIndex = 0;
    }
    setSelectedImageIndex(nextIndex);
  };
  const onPrev = (e) => {
    if (e) {
      e.stopPropagation();
    }
    let prevIndex = selectedImageIndex - 1;
    if (selectedImageIndex === 0) {
      prevIndex = images.length - 1;
    }
    setSelectedImageIndex(prevIndex);
  };
  useMousetrap('right', () => onNext());
  useMousetrap('left', () => onPrev());
  return (
    <>
      <S.Container thumbnailWidth={props.thumbnailWidth}>
        {thumbnails.map((thumbnail, index) => {
          let hovered = false;
          thumbnail = thumbnail.file.localFile;
          return <button style={{
            ...thumbnailStyle
          }} aria-label="Bild öffnen" key={thumbnail.name} onClick={() => {
            onImageClick(index)
          }}>
            <Img fluid={thumbnail.childImageSharp.fluid}/>
          </button>;
        })}
      </S.Container>
      {lightboxOpen &&
        <DialogOverlay onDismiss={onClose} overlayBgColor={props.overlayBgColor} css={S.dialogOverlay}>
          <S.Counter>{selectedImageIndex + 1} / {images.length}</S.Counter>
          <S.Button aria-label="Schließen" className="int-btn-close" onClick={(e) => {
            onClose(e)
          }}><GoX/></S.Button>
          <S.Button aria-label="Vorheriges Bild" className="int-btn-prev" onClick={(e) => {
            onPrev(e)
          }}><FaArrowLeft/></S.Button>
          <DialogContent css={S.dialogContent}
                         ar={images[selectedImageIndex].file.localFile.childImageSharp.fluid.aspectRatio}>
            <img src={images[selectedImageIndex].file.localFile.childImageSharp.fluid.src}
                 alt={images[selectedImageIndex].name}/>
          </DialogContent>
          <S.Button aria-label="Nächstes Bild" className="int-btn-next" onClick={(e) => {
            onNext(e)
          }}><FaArrowRight/></S.Button>
        </DialogOverlay>}
    </>
  )
};

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
  thumbnails: PropTypes.array.isRequired,
  thumbnailWidth: PropTypes.string,
  overlayBgColor: PropTypes.string,
  type: PropTypes.oneOf(['slider', 'grid', 'masonry'])
};

Lightbox.defaultProps = {
  thumbnailWidth: '250px',
  overlayBgColor: 'rgba(255,255,255,0.95)',
};

export default Lightbox;
